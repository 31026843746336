::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active {
  background-color: rgba(0, 0, 0, 0.9);
}

* {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  /* display: none; */
}